import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import LandingPage from './content/LandingPage';
import LoginPage from './content/LoginPage';
import { Route, Routes } from 'react-router-dom';
import { Content } from '@carbon/react';


class App extends Component {
  render() {
    return (
      <div>
        <Content>
          <Routes>
            <Route path="/" element={<LandingPage/>} />
            <Route path="/login" element={<LoginPage/>} />
          </Routes>
        </Content>
      </div>
    )
  }
}
export default App;
